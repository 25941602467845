export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/triajes',
    name: 'salud-triajes-list',
    component: () => import('@/views/salud/triaje/triajes-list/TriajesList.vue'),
    meta: {
      pageTitle: 'Gestión de triajes',
      breadcrumb: [
        {
          text: 'Lista de triajes',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.triajes.index',
      resource: 'api',
    },
  },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/salud/user/users-view/UsersView.vue'),
  // },
  {
    path: '/salud/triajes/edit/:id',
    name: 'salud-triajes-edit',
    component: () => import('@/views/salud/triaje/triajes-list/TriajesEdit.vue'),
    meta: {
      requiresAuth: true,
      action: 'salud.triajes.show',
      resource: 'api',
    },
  },
  {
    path: '/salud/triajes/create/:persona',
    name: 'salud-triajes-create',
    component: () => import('@/views/salud/triaje/triajes-list/TriajesEdit.vue'),
    action: 'salud.triajes.show',
    resource: 'api',
  },
]
