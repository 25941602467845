import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import ElementUI from 'element-ui'
// import VSwitch from 'v-switch-case'
import axios from '@axios'
import router from './router'
import store from './store'
import App from './App.vue'
import { toastSuccess, toastError, toastAlert } from './mixins/mixinToast'
// Global Components
import './global-components'

// 3rd party plugins
// import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
// import '@/libs/clipboard'
// import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

Vue.mixin({ methods: { toastSuccess, toastError, toastAlert } })
// import '@/libs/tour'
// Axios Mock Adapter
// import '@/@fake-db/db'
// BSV Plugin Registration
// Vue.use(VSwitch)
// Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

axios.get('/administrador/ajustes').then(res => {
  store.state.ajustesApp.institucion = res.data.data.find(val => val.nombre === 'institucion').valor
  store.state.ajustesApp.titulo = res.data.data.find(val => val.nombre === 'título').valor
  store.state.ajustesApp.nombreAnio = res.data.data.find(val => val.nombre === 'nombre del año').valor
  store.state.ajustesApp.piePagina1 = res.data.data.find(val => val.nombre === 'pie pagina 1').valor
  store.state.ajustesApp.piePagina2 = res.data.data.find(val => val.nombre === 'pie pagina 2').valor
  store.state.ajustesApp.icono = res.data.data.find(val => val.nombre === 'icono').valor
  store.state.ajustesApp.logo = res.data.data.find(val => val.nombre === 'logo').valor
  store.state.ajustesApp.logoPequeno = res.data.data.find(val => val.nombre === 'logo pequeño').valor
  store.state.ajustesApp.logoTexto1 = res.data.data.find(val => val.nombre === 'logo texto 1').valor
  store.state.ajustesApp.logoTexto2 = res.data.data.find(val => val.nombre === 'logo texto 2').valor
  store.state.ajustesApp.imagenFondoLogin = res.data.data.find(val => val.nombre === 'imagen fondo login').valor
  store.state.ajustesApp.imagenFondoBody = res.data.data.find(val => val.nombre === 'imagen fondo body').valor
  store.state.ajustesApp.imagenDefecto = res.data.data.find(val => val.nombre === 'imagen por defecto').valor
  store.state.ajustesApp.manualUsuarioEstudiante = res.data.data.find(val => val.nombre === 'manual usuario estudiante').valor
  store.state.ajustesApp.manualUsuarioAdministrativo = res.data.data.find(val => val.nombre === 'manual usuario administrativo').valor
  store.state.ajustesApp.manualAdministrador = res.data.data.find(val => val.nombre === 'manual administrador').valor
  store.state.ajustesApp.vtUsuarioEstudiante = res.data.data.find(val => val.nombre === 'video tutorial usuario estudiante').valor
  store.state.ajustesApp.vtUsuarioAdministrativo = res.data.data.find(val => val.nombre === 'video tutorial usuario administrativo').valor
  store.state.ajustesApp.vtUsuarioAdministrador = res.data.data.find(val => val.nombre === 'video tutorial administrador').valor
  store.state.ajustesApp.pesoArchivo = res.data.data.find(val => val.nombre === 'Peso archivo (B)').valor
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
