import { canNavigate } from '@/libs/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import atenciones from './routes/salud/atenciones'
// import citas from './routes/salud/citas'
import topicos from './routes/salud/topicos'
import triajes from './routes/salud/triajes'
import farmacia from './routes/salud/farmacia'
import hostigamiento from './routes/social/hostigamiento'
import convocatorias from './routes/social/convocatorias'
import residencia from './routes/social/residencia'
import formulario from './routes/administrador/formulario'
import visitas from './routes/social/visitas'
import soporteSocial from './routes/social/soporte_social'
import opinionPostulanteBeca from './routes/social/opinion_postulante_beca'
import becariosTodos from './routes/social/becarios_todos'
import comedor from './routes/social/comedor'
import bolsaTrabajo from './routes/social/bolsa'
import ocadeServicioDeportivo from './routes/ocade/servicioDeportivo'
import ocadeServicioCultural from './routes/ocade/servicioCultural'
import ocadePRODAC from './routes/ocade/prodac'
import historias from './routes/salud/historias'
import usuario from './routes/administrador/usuario'
import ajustes from './routes/administrador/ajustes'
import authentication from './routes/authentication'
import error from './routes/error'
import mescellaneous from './routes/miscellaneous'
import constancia from './routes/salud/constancia'
import estudiante from './routes/estudiante/estudiante'
import emergencia from './routes/salud/emergencia'
import ocadeProfesor from './routes/ocade/ocadeProfesor'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-home' } },
    {
      path: '/dashboard/home',
      name: 'dashboard-home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/page/blank/descarga/:valor',
      name: 'page-blank',
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
      props: true,
      component: () => import('@/views/components/Pages/LayoutBlank.vue'),
    },
    ...ajustes,
    ...triajes,
    ...atenciones,
    ...historias,
    ...constancia,
    ...emergencia,
    ...topicos,
    ...farmacia,
    ...hostigamiento,
    ...convocatorias,
    ...residencia,
    ...formulario,
    ...visitas,
    ...soporteSocial,
    ...opinionPostulanteBeca,
    ...becariosTodos,
    ...comedor,
    ...bolsaTrabajo,
    ...ocadeServicioDeportivo,
    ...ocadeServicioCultural,
    ...ocadePRODAC,
    ...usuario,
    ...estudiante,
    ...ocadeProfesor,
    ...authentication,
    ...error,
    ...mescellaneous,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

/* eslint-disable */
router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!isLoggedIn) {
      next({ name: 'auth-login' })
    } else {
      if (!canNavigate(to)) {
       return next({ name: 'no-autorizado' })
      }
      next()
    }
    
  } else {
    next() 
  }
})

export default router
