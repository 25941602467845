import Vue from 'vue'

// axios
import axios from 'axios'

const HOST = `${process.env.VUE_APP_HOST}`
// const TOKEN = localStorage.getItem('token')
// if (TOKEN) {
//   axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`
// }
// axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
const axiosIns = axios.create({
  baseURL: HOST,
  withCredentials: true,
})

Vue.prototype.$http = axiosIns

export default axiosIns
