import axios from '@axios'

export default [
  {
    path: '/asistencia-social/visitas',
    name: 'social-visitas-list',
    component: () => import('@/views/social/visitas-list/VisitaList.vue'),
    meta: {
      pageTitle: 'Gestión de asistencia social',
      breadcrumb: [
        {
          text: 'Visitas',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'social.asistencia.visita.index',
      resource: 'api',
    },
  },
  {
    path: '/servicio-social/visita-social/:id/pdf',
    name: 'download-pdf-visita-social',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      axios({
        url: `/servicio-social/asistencia-social/visita/${to.params.id}/pdf`,
        method: 'GET',
        params: { url_pdf: `${window.location.origin}${to.path}` },
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `VISITA SOCIAL - COD-${to.params.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
      // next(from)
    },
    meta: {
      requiresAuth: true,
      action: 'social.asistencia.visita.index',
      resource: 'api',
    },
  },
  // {
  //   path: '/asistencia-social/denuncias/pre-calificar',
  //   name: 'social-asistencias-calificar',
  //   component: () => import('@/views/servicio-social/asistencia-social/asistencias-list/AsistenciaSocialPreCalificar-eliminar.vue'),
  // },
]
