export default [
  {
    path: '/salud-emergencias',
    name: 'salud-emergencia-list',
    component: () => import('@/views/salud/emergencia/EmergenciaList.vue'),
    meta: {
      pageTitle: 'Registro de emergencias',
      breadcrumb: [
        {
          text: 'Emergencias',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.emergencias.index',
      resource: 'api',
    },
  },
  {
    path: '/salud-derivados',
    name: 'salud-derivados-list',
    component: () => import('@/views/salud/derivados/DerivadosList.vue'),
    meta: {
      pageTitle: 'Registro de derivados',
      breadcrumb: [
        {
          text: 'Derivados',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.derivados.index',
      resource: 'api',
    },
  },
]
