import axios from '@axios'

export default [
  {
    path: '/atenciones',
    name: 'salud-atencion-list',
    component: () => import('@/views/salud/atencion/atenciones-list/AtencionesList.vue'),
    meta: {
      requiresAuth: true,
      action: 'salud.atenciones.index',
      resource: 'api',
      pageTitle: 'Gestión de atenciones',
      breadcrumb: [
        {
          text: 'Atenciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/atenciones/ver/:id',
    name: 'salud-atencion-view',
    component: () => import('@/views/salud/atencion/atenciones-view/AtencionView.vue'),
    meta: {
      requiresAuth: true,
      action: 'salud.atenciones.show',
      resource: 'api',
    },
  },
  {
    path: '/servicio-salud/farmacia/:id/detalle',
    name: 'download-pdf-receta',
    beforeEnter: to => {
      axios({
        url: `/servicio-salud/farmacia/${to.params.id}/detalle`,
        method: 'GET',
        params: { url_pdf: `${window.location.origin}${to.path}` },
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Receta- COD-${to.params.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
    },
    meta: {
      requiresAuth: true,
      action: 'salud.recetas.url',
      resource: 'api',
    },
  },
  {
    path: '/salud/reporte/atenciones',
    name: 'salud-reporte-atenciones',
    component: () => import('@/views/salud/reportes/VistaGeneral.vue'),
    meta: {
      requiresAuth: true,
      action: 'salud.reporte.atenciones.grafico',
      resource: 'api',
    },
  },

  // SATISFACCIÓN ESTUDIANTE
  {
    path: '/servicio-salud/satisfaccion-estudiante',
    name: 'satisfaccion-estudiante-salud',
    component: () => import('@/views/salud/satisfaccion-estudiante/SatisfaccionSalud.vue'),
    meta: {
      requiresAuth: true,
      action: 'salud.encuesta.satisfaccion.estudiante',
      resource: 'api',
      pageTitle: 'SATISFACCIÓN ESTUDIANTE',
      breadcrumb: [
        {
          text: 'Formularios',
          active: true,
        },
      ],
    },
  },
]
