// import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    institucion: null,
    titulo: null,
    nombreAnio: null,
    piePagina1: null,
    piePagina2: null,
    icono: null,
    logo: null,
    logoPequeno: null,
    logoTexto1: null,
    logoTexto2: null,
    imagenFondoLogin: null,
    imagenFondoBody: null,
    imagenDefecto: null,
    manualUsuarioEstudiante: null,
    manualUsuarioAdministrativo: null,
    manualAdministrador: null,
    vtUsuarioEstudiante: null,
    vtUsuarioAdministrativo: null,
    vtUsuarioAdministrador: null,
    pesoArchivo: null,

    // shallShowOverlay: false,
  },
  getters: {
  },
  mutations: {
    updateDataUsuario(state, data) {
      state.user = data
    },
    updateDataUsuarioPermissions(state, data) {
      state.permissions = data
    },
  },
  actions: {},
}
