export default [
  {
    path: '/farmacia',
    name: 'farmacia.index',
    component: () => import('@/views/salud/farmacia/medicamento-list/MedicamentoList.vue'),
    meta: {
      pageTitle: 'Farmacia',
      breadcrumb: [
        {
          text: 'Catálogo',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.farmacia.producto.index',
      resource: 'api',
    },
  },
  {
    path: '/farmacia/create',
    name: 'farmacia.create',
    component: () => import('@/views/salud/farmacia/medicamento-create/MedicamentoCreate.vue'),
    meta: {
      pageTitle: 'Farmacia - Crear Producto',
      breadcrumb: [
        {
          text: 'Catálogo',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.farmacia.producto.store',
      resource: 'api',
    },
  },
  {
    path: '/farmacia/ingresos',
    name: 'farmacia.input.list',
    component: () => import('@/views/salud/farmacia/ingresos-list/IngresosList.vue'),
    meta: {
      pageTitle: 'Farmacia',
      breadcrumb: [
        {
          text: 'Ingresos',
          active: true,
        },
      ],
    },
    requiresAuth: true,
    action: 'salud.farmacia.compra.index',
    resource: 'api',
  },
  {
    path: '/farmacia/salidas-bajas',
    name: 'farmacia.ouput.list',
    component: () => import('@/views/salud/farmacia/salidas-list/SalidasList.vue'),
    meta: {
      pageTitle: 'Farmacia',
      breadcrumb: [
        {
          text: 'Salidas',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.farmacia.venta.index',
      resource: 'api',
    },
  },
  {
    path: '/farmacia/ingresos/nueva-compra',
    name: 'farmacia.ingresos.nuevaCompra',
    component: () => import('@/views/salud/farmacia/ingresos-list/compra-nueva/NuevaCompraView.vue'),
    /* meta: {
      pageTitle: 'Farmacia',
      breadcrumb: [
        {
          text: 'Nueva compra',
          active: true,
        },
      ],
    }, */
  },
]
