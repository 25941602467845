export default [
  {
    path: '/ficha-medica-estudiante',
    name: 'ficha-medica-estudiante',
    component: () => import('@/views/estudiante/salud/FichaMedica.vue'),
    meta: {
      pageTitle: 'Servicio Salud',
      breadcrumb: [
        {
          text: 'Ficha médica',
          active: true,
        },
      ],
    },
  },
  {
    path: '/social/mis-postulaciones',
    name: 'mis-postulaciones-estudiante',
    component: () => import('@/views/estudiante/social/MisPostulacionesList.vue'),
    meta: {
      pageTitle: 'Servicio social',
      breadcrumb: [
        {
          text: 'Mis postulaciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/deporteycultura/mis-talleres',
    name: 'mis-talleres-estudiante',
    component: () => import('@/views/estudiante/ocade/MisTalleresList.vue'),
    meta: {
      pageTitle: 'Servicio Deporte y Cultura',
      breadcrumb: [
        {
          text: 'Mis Talleres',
          active: true,
        },
      ],
    },
  },

]
