export default [
  {
    path: '/deporte-y-cultural/profesores',
    name: 'ocade-profesores',
    component: () => import('@/views/ocade/profesores/ProfesoresList.vue'),
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.profesor.index',
      resource: 'api',
      pageTitle: 'DEPORTIVO Y CULTURAL',
      breadcrumb: [
        {
          text: 'Profesores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/deporte-y-cultural/profesores/talleres',
    name: 'ocade-profesores-talleres',
    component: () => import('@/views/ocade/profesor-talleres/ProfesorTalleres.vue'),
    meta: {

      requiresAuth: true,
      action: 'PROF-OCADE',
      resource: 'api',

      pageTitle: 'TALLERES',
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },

]
