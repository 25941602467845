import axios from '@axios'

export default [
  {
    path: '/servicio-cultural',
    name: 'ocade-servicio-cultural',
    component: () => import('@/views/ocade/talleres-globales/TalleresGlobalesCulturales.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-CULTURAL',
      resource: 'api',
      pageTitle: 'Servicio Cultural',
      breadcrumb: [
        {
          text: 'Talleres Globales Culturales',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-CULTURAL',
    },
  },
  {
    path: '/servicio-cultural/global/:id/talleres',
    name: 'ocade-servicio-cultural-talleres',
    component: () => import('@/views/ocade/talleres-globales/talleres/TalleresListar.vue'),
    beforeEnter: (to, from, next) => {
      axios.get(`/servicio-cultural-deportivo/OCADE-CULTURAL/global/${to.params.id}`)
        .then(() => next())
        .catch(error => {
          next({ name: 'ocade-servicio-cultural' })
          console.log('ERRORRRR: BEFORE ENTER: ', error.response)
        })
    },
    meta: {
      requiresAuth: true,
      action: 'OCADE-CULTURAL',
      resource: 'api',
      pageTitle: 'Servicio Cultural',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-cultural' },
        },
        {
          text: 'Talleres',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-CULTURAL',
    },
  },
  {
    path: '/servicio-cultural/global/:globalId/taller/:id/estudiantes',
    name: 'ocade-servicio-cultural-taller-estudiantes',
    component: () => import('@/views/ocade/talleres-globales/talleres/estudiantes/EstudiantesListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.matricula.index',
      resource: 'api',
    },
    props: {
      codigo: 'OCADE-CULTURAL',
      propNameTaller: 'TALLER CULTURAL',
      pageTitle: 'Servicio Cultural',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-cultural' },
        },
        {
          text: 'Talleres',
          to: { name: 'ocade-servicio-cultural-talleres', params: { id: 0 } },
        },
        {
          text: 'Estudiantes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-cultural/global/:globalId/taller/:id/actividades',
    name: 'ocade-servicio-cultural-taller-actividades',
    component: () => import('@/views/ocade/talleres-globales/talleres/actividades/TallerActividadesListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.actividad.index',
      resource: 'api',
    },
    props: {
      codigo: 'OCADE-CULTURAL',
      propNameTaller: 'TALLER CULTURAL',
      pageTitle: 'Servicio Cultural',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-cultural' },
        },
        {
          text: 'Talleres',
          to: { name: 'ocade-servicio-cultural-talleres', params: { id: 0 } },
        },
        {
          text: 'Actividades',
          active: true,
        },
      ],
    },
  },

  // DERIVADOS
  {
    path: '/servicio-cultural/derivados',
    name: 'derivados-cultural-list',
    component: () => import('@/views/ocade/derivados/DerivadosCulturalesList.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-CULTURAL',
      resource: 'api',
      pageTitle: 'Servicio cultural',
      breadcrumb: [
        {
          text: 'Derivados desde el servicio de salud',
          active: true,
        },
      ],
    },
  },
  // ACTIVIDADES GLOBALES
  {
    path: '/servicio-cultural-actividades-generales',
    name: 'ocade-servicio-cultural-actividades-generales',
    component: () => import('@/views/ocade/actividades-globales/ActividadesCulturaListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-CULTURAL',
      resource: 'api',
      pageTitle: 'Servicio Cultural',
      breadcrumb: [
        {
          text: 'Actividades Generales',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-CULTURAL',
      propNameTaller: 'ACTIVIDADES DE RECREACIÓN CULTURAL',
    },
  },
]
