export default [
  {
    path: '/usuarios/listar',
    name: 'usuarios-listar',
    component: () => import('@/views/administrador/usuarios/UsuariosListar.vue'),
    props: true,
    meta: {
      pageTitle: 'GESTIÓN DE USUARIOS',
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'administrador.usuario.store',
      resource: 'api',
    },
  },
  {
    path: '/rols-permissions',
    name: 'roles-listar',
    component: () => import('@/views/administrador/roles/RolesListar.vue'),
    props: true,
    meta: {
      pageTitle: 'GESTIÓN DE ROLES',
      breadcrumb: [
        {
          text: 'Roles y permisos',
          active: true,
        },
      ],
    },
    requiresAuth: true,
    action: 'administrador.role.index',
    resource: 'api',
  },

]
