import axios from '@axios'
import { isUserLoggedIn } from '@/auth/utils'

export default [
  {
    path: '/servicio-social/convocatorias',
    name: 'social-convocatorias-list',
    component: () => import('@/views/servicio-social/convocatorias/convocatorias-list/ConvocatoriasList.vue'),
    meta: {
      pageTitle: 'Gestión de convocatorias',
      breadcrumb: [
        {
          text: 'Convocatorias',
          active: true,
        },
      ],
      layout: isUserLoggedIn() ? '' : 'full',
      requiresAuth: false,
      // action: 'social.becas.convocatoria.index',
      // resource: 'api',
    },
  },

  {
    path: '/servicio-social/convocatoria/:id',
    name: 'bu-convocatorias-postular',
    component: () => import('@/views/servicio-social/convocatorias/postulacion/ConvocatoriaPostular.vue'),
    beforeEnter: (to, from, next) => {
      axios.post(`/servicio-social/beca/convocatoria/${to.params.id}/pre-inscripcion`).then(() => {
        next()
      }).catch(() => {
        next({ name: 'social-convocatorias-list' })
      })
    },
    props: true,
    meta: {
      requiresAuth: true,
      action: 'social.becas.convocatoria.postulante.store',
      resource: 'api',
    },
  },

  {
    path: '/servicio-social/convocatoria/:id/postulantes',
    name: 'bu-convocatoria-postulantes',
    component: () => import('@/views/servicio-social/convocatorias/postulantes-list/PostulantesConvocatoriaList.vue'),
    meta: {
      pageTitle: 'Gestión de convocatorias',
      breadcrumb: [
        {
          text: 'Postulantes',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'social.becas.convocatoria.store',
      resource: 'api',
    },
    props: true,
  },
  {
    path: '/descargarPDfConvocatoria/:idConvocatoria/pdf',
    name: 'descargar-resultado-PDF-convocatoria',
    meta: {
      requiresAuth: false,
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      axios({
        url: `${process.env.VUE_APP_HOST}servicio-social/beca/convocatoria/${to.params.idConvocatoria}/resultados`,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', 'file.pdf')

          document.body.appendChild(link)
          link.click()
        })
    },
  },

]
