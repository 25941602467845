export default [
  {
    path: '/topicos',
    name: 'topicos.index',
    component: () => import('@/views/salud/topico/topicos-list/TopicoList.vue'),
    meta: {
      pageTitle: 'Gestión de topicos',
      breadcrumb: [
        {
          text: 'topicos',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/topicos/registrar',
  //   name: 'topicos.create',
  //   component: () => import('@/views/salud/topico/topicos-create/topicosRegistrar.vue'),
  // },
  // {
  //   path: '/topicos/topico-add',
  //   name: 'topicos.add',
  //   component: () => import('@/views/salud/topico/topicos-create/topicosAdd.vue'),
  // },
  // {
  //   path: '/atenciones/create/:id',
  //   name: 'atenciones.create',
  //   component: () => import('@/views/salud/atenciones/AtencionForm.vue'),
  // },
  // {
  //   path: '/topicos/:id/edit',
  //   name: 'topicos.update',
  //   component: () => import('@/views/topicos/topicos-edit/topicosEdit.vue'),
  // },
]
