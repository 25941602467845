import axios from '@axios'

export default [

  {
    path: '/hostigamiento/denuncias/buzon',
    name: 'social-buzon-denuncias',
    component: () => import('@/views/servicio-social/hostigamiento/buzon-denuncia/BuzonDenunciasList.vue'),
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.bandeja_denuncias',
      pageTitle: 'Gestión de denuncias',
      breadcrumb: [
        {
          text: 'Buzón',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hostigamiento/denuncias',
    name: 'social-denuncias',
    component: () => import('@/views/servicio-social/hostigamiento/denuncia/DenunciasList.vue'),
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
      pageTitle: 'Gestión de denuncias',
      breadcrumb: [
        {
          text: 'Denuncias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hostigamiento/denuncias/reportes',
    name: 'social-denuncias-reportes',
    component: () => import('@/views/servicio-social/hostigamiento/reportes/ReportesHostigamiento.vue'),
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
    },
  },
  {
    path: '/hostigamiento/denuncias/respuestas',
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
    },
    name: 'respuestas-denuncias-hostigamiento',
    component: () => import('@/views/servicio-social/hostigamiento/denuncia/respuestas/RespuestasDenunciasHostigamiento.vue'),
  },
  {
    path: '/f/servicio-social/denuncias/:idDenuncia/pdf',
    name: 'download-PDF-precalificar',
    meta: {
      requiresAuth: true,
      layout: 'full',
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      axios({
        url: `/servicio-social/prevencion-hostigamiento/denuncias/${to.params.idDenuncia}/precalificar_pdf`,
        method: 'GET',
        params: { url_pdf: `${window.location.origin}${to.path}` },
        responseType: 'blob', // important
      })
        .then(response => {
          console.log('PDF RESPONSE:', response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `InformePrecalificacion${to.params.idDenuncia}.pdf`)
          document.body.appendChild(link)
          link.click()
          next({ name: 'page-blank', params: { valor: 1 } })
        }).catch(() => {
          next({ name: 'page-blank', params: { valor: 0 } })
        })
    },
  },

  // ENCUESTA
  {
    path: '/hostigamiento/encuestas/respuestas',
    name: 'respuestas-encuestas-hostigamiento',
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
    },
    component: () => import('@/views/servicio-social/hostigamiento/encuesta/respuestas/RespuestasEncuestaHostigamiento.vue'),
  },
  {
    path: '/hostigamiento/formulario/encuesta',
    name: 'gestion-formulario-encuesta',
    meta: {
      requiresAuth: true,
      resource: 'api',
      action: 'social.hostigamiento.denuncias.index',
    },
    component: () => import('@/views/servicio-social/hostigamiento/encuesta/GestionFormularioEncuestas.vue'),
  },

]
