import axios from '@axios'

export default [
  {
    path: '/prodac',
    name: 'ocade-prodac',
    component: () => import('@/views/ocade/talleres-globales/ProgramasGlobalesProdac.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-ALT-COMP',
      resource: 'api',
      pageTitle: 'PRODAC',
      breadcrumb: [
        {
          text: 'Programa Deportivo de Alta Competencia',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-ALT-COMP',
    },
  },
  {
    path: '/prodac/global/:id/programas',
    name: 'ocade-prodac-programas',
    component: () => import('@/views/ocade/talleres-globales/talleres/TalleresListar.vue'),
    beforeEnter: (to, from, next) => {
      axios.get(`/servicio-cultural-deportivo/OCADE-ALT-COMP/global/${to.params.id}`)
        .then(() => next())
        .catch(() => {
          next({ name: 'ocade-prodac-programas' })
        })
    },
    meta: {
      requiresAuth: true,
      action: 'OCADE-ALT-COMP',
      resource: 'api',
      pageTitle: 'PRODAC',
      breadcrumb: [
        {
          text: 'Programas Globales',
          to: { name: 'ocade-prodac' },
        },
        {
          text: 'Programas',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-ALT-COMP',
    },
  },
  {
    path: '/prodac/global/:globalId/programa/:id/estudiantes',
    name: 'ocade-prodac-programa-estudiantes',
    component: () => import('@/views/ocade/talleres-globales/talleres/estudiantes/EstudiantesListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.matricula.index',
      resource: 'api',
    },
    props: {
      codigo: 'OCADE-ALT-COMP',
      propNameTaller: 'PROGRAMA DEPORTIVO',
      pageTitle: 'PRODAC',
      breadcrumb: [
        {
          text: 'Programas Globales',
          to: { name: 'ocade-prodac' },
        },
        {
          text: 'Programas',
          to: { name: 'ocade-prodac-programas', params: { id: 0 } },
        },
        {
          text: 'Estudiantes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/prodac/global/:globalId/taller/:id/actividades',
    name: 'ocade-prodac-programa-actividades',
    component: () => import('@/views/ocade/talleres-globales/talleres/actividades/TallerActividadesListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.actividad.index',
      resource: 'api',
    },
    props: {
      codigo: 'OCADE-ALT-COMP',
      propNameTaller: 'PROGRAMA DEPORTIVO',
      pageTitle: 'PRODAC',
      breadcrumb: [
        {
          text: 'Programas Globales',
          to: { name: 'ocade-prodac' },
        },
        {
          text: 'Programas',
          to: { name: 'ocade-prodac-programas', params: { id: 0 } },
        },
        {
          text: 'Actividades',
          active: true,
        },
      ],
    },
  },

  // EVENTOS DEPORTIVOS
  {
    path: '/prodac-eventos',
    name: 'ocade-prodac-eventos',
    component: () => import('@/views/ocade/actividades-globales/ActividadesProdacListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-ALT-COMP',
      resource: 'api',
      pageTitle: 'PRODAC',
      breadcrumb: [
        {
          text: 'Eventos Deportivos',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-ALT-COMP',
      propNameTaller: 'EVENTOS Y COMPETENCIAS DEPORTIVAS',
    },
  },
]
