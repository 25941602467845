export default [
  // Ambientes
  {
    path: '/servicio-social/residencia/ambientes',
    name: 'residencia-ambientes-list',
    component: () => import('@/views/servicio-social/residencia-universitaria/ambientes/AmbientesResidenciaList.vue'),
    meta: {
      pageTitle: 'Residencia Universitaria',
      requiresAuth: true,
      action: 'social.residencia.habitacion.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Ambientes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-social/residencia/ambientes/:id/becarios',
    name: 'residencia-ambientes-becarios-list',
    component: () => import('@/views/servicio-social/residencia-universitaria/ambientes/AmbienteBecarioForm.vue'),
    meta: {
      pageTitle: 'Residencia Universitaria',
      requiresAuth: true,
      action: 'social.residencia.habitacion.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Ambientes x Becarios',
          active: true,
        },
      ],
    },
  },
  // Becarios
  {
    path: '/servicio-social/residencia/becarios',
    name: 'residencia-becarios-list',
    component: () => import('@/views/servicio-social/residencia-universitaria/becarios/BecariosResidenciaList.vue'),
    meta: {
      pageTitle: 'Residencia Universitaria',
      requiresAuth: true,
      action: 'social.residencia.becarios.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Becarios',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/servicio-social/residencia/becarios/:id/ambiente',
  //   name: 'residencia-becario-ambientes-list',
  //   component: () => import('@/views/servicio-social/residencia-universitaria/becarios/ModalBecarioAmbienteForm.vue'),
  //   meta: {
  //     pageTitle: 'Residencia Universitaria',
  //     breadcrumb: [
  //       {
  //         text: 'Becario x Ambientes',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
