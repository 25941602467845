export default [
  {
    path: '/administrador/ajustes-app',
    name: 'administrador-ajustes-app',
    component: () => import('@/views/administrador/ajustes/AjustesApp.vue'),
    props: true,
    meta: {
      pageTitle: 'AJUSTES',
      breadcrumb: [
        {
          text: 'Aplicación',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'administrador.usuario.store',
      resource: 'api',
    },
  },

]
