import axios from '@axios'

export default [
  {
    path: '/servicio-deportivo',
    name: 'ocade-servicio-deportivo',
    component: () => import('@/views/ocade/talleres-globales/TalleresGlobalesDeportivos.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-DEPORTIVO',
      resource: 'api',
      pageTitle: 'Servicio Deportivo',
      breadcrumb: [
        {
          text: 'Talleres Globales Deportivos',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-DEPORTIVO',
    },
  },
  {
    path: '/servicio-deportivo/global/:id/talleres',
    name: 'ocade-servicio-deportivo-talleres',
    component: () => import('@/views/ocade/talleres-globales/talleres/TalleresListar.vue'),
    beforeEnter: (to, from, next) => {
      axios.get(`/servicio-cultural-deportivo/OCADE-DEPORTIVO/global/${to.params.id}`)
        .then(() => next())
        .catch(() => {
          next({ name: 'ocade-servicio-deportivo' })
        })
    },
    meta: {
      requiresAuth: true,
      action: 'OCADE-DEPORTIVO',
      resource: 'api',
      pageTitle: 'Servicio Deportivo',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-deportivo' },
        },
        {
          text: 'Talleres',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-DEPORTIVO',
    },
  },

  {
    path: '/servicio-deportivo/global/:globalId/taller/:id/estudiantes',
    name: 'ocade-servicio-deportivo-taller-estudiantes',
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.matricula.index',
      resource: 'api',
    },
    component: () => import('@/views/ocade/talleres-globales/talleres/estudiantes/EstudiantesListar.vue'),
    beforeEnter: (to, from, next) => {
      axios.get(`/servicio-cultural-deportivo/OCADE-DEPORTIVO/global/${to.params.globalId}/taller/${to.params.id}`)
        .then(() => next())
        .catch(() => {
          next('/')
        })
    },
    props: {
      codigo: 'OCADE-DEPORTIVO',
      propNameTaller: 'TALLER DEPORTIVO',
      pageTitle: 'Servicio Deportivo',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-deportivo' },
        },
        {
          text: 'Talleres',
          to: { name: 'ocade-servicio-deportivo-talleres', params: { id: 0 } },
        },
        {
          text: 'Estudiantes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-deportivo/global/:globalId/taller/:id/actividades',
    name: 'ocade-servicio-deportivo-taller-actividades',
    meta: {
      requiresAuth: true,
      action: 'cultural_deportivo.global.taller.actividad.index',
      resource: 'api',
    },
    component: () => import('@/views/ocade/talleres-globales/talleres/actividades/TallerActividadesListar.vue'),
    props: {
      codigo: 'OCADE-DEPORTIVO',
      propNameTaller: 'TALLER DEPORTIVO',
      pageTitle: 'Servicio Deportivo',
      breadcrumb: [
        {
          text: 'Talleres Globales',
          to: { name: 'ocade-servicio-deportivo' },
        },
        {
          text: 'Talleres',
          to: { name: 'ocade-servicio-deportivo-talleres', params: { id: 0 } },
        },
        {
          text: 'Actividades',
          active: true,
        },
      ],
    },
  },

  // ACTIVIDADES GLOBALES
  {
    path: '/servicio-deportivo-actividades-generales',
    name: 'ocade-servicio-deportivo-actividades-generales',
    component: () => import('@/views/ocade/actividades-globales/ActividadesDeporteListar.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-DEPORTIVO',
      resource: 'api',
      pageTitle: 'Servicio Deportivo',
      breadcrumb: [
        {
          text: 'Actividades Generales',
          active: true,
        },
      ],
    },
    props: {
      codigo: 'OCADE-DEPORTIVO',
      propNameTaller: 'ACTIVIDADES DE RECREACIÓN DEPORTIVA',
    },
  },

  // DERIVADOS
  {
    path: '/servicio-deportivo/derivados',
    name: 'derivados-deporte-list',
    component: () => import('@/views/ocade/derivados/DerivadosDeportesList.vue'),
    meta: {
      requiresAuth: true,
      action: 'OCADE-DEPORTIVO',
      resource: 'api',
      pageTitle: 'Servicio deportivo',
      breadcrumb: [
        {
          text: 'Derivados desde el servicio de salud',
          active: true,
        },
      ],
    },
  },

  // RUTA PARA IMPRIMIR CERTIFICADO DE HABER APROBADO UN TALLER
  {
    path: '/descargarPDfCertificado/:idMatricula/pdf',
    name: 'descargar-certificado-PDF-tallerAprobado',
    meta: {
      requiresAuth: false,
    },
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      axios({
        url: `${process.env.VUE_APP_HOST}servicio-cultural-deportivo/${to.params.idMatricula}/certificado`,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `CertificadoDIBU${to.params.idMatricula}.pdf`)

          document.body.appendChild(link)
          link.click()
          next({ name: 'page-blank', params: { valor: 1 } })
        }).catch(() => {
          next({ name: 'page-blank', params: { valor: 0 } })
        })
    },
  },

  // SATISFACCIÓN ESTUDIANTE
  {
    path: '/deporte-cultura/satisfaccion-estudiante',
    name: 'satisfaccion-estudiante-ocade',
    component: () => import('@/views/ocade/satisfaccion-estudiante/SatisfaccionOcade.vue'),
    meta: {
      requiresAuth: true,
      action: 'JEFE-OCADE',
      resource: 'api',
      pageTitle: 'SATISFACCIÓN ESTUDIANTE',
      breadcrumb: [
        {
          text: 'Formularios',
          active: true,
        },
      ],
    },
  },

]
