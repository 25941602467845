export default [
  {
    path: '/historias-clinicas',
    name: 'salud-historia-clinica-list',
    component: () => import('@/views/salud/historia/HistoriaClinicaList.vue'),
    meta: {
      pageTitle: 'Gestión de historias clinicas',
      breadcrumb: [
        {
          text: 'Historias',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.historias.index',
      resource: 'api',
    },
  },
]
