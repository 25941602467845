export default [
  {
    path: '/servicio-social/becarios',
    name: 'becarios-todos-list',
    component: () => import('@/views/servicio-social/becarios-todos/BecariosList.vue'),
    meta: {
      requiresAuth: true,
      action: 'social.becas.comedor.index',
      resource: 'api',
      pageTitle: 'Gestión de becarios',
      breadcrumb: [
        {
          text: 'becarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-social/infracciones',
    name: 'infracciones-becarios',
    component: () => import('@/views/servicio-social/infracciones/InfraccionesList.vue'),
    meta: {
      pageTitle: 'Gestión de Infracciones',
      requiresAuth: true,
      action: 'social.infracciones.infraccion.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Infracciones',
          active: true,
        },
      ],
    },
  },

]
