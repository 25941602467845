import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

function toastSuccess(text = '', position = 'top-right', title = '¡Registro Exitoso!', icon = 'CheckSquareIcon', variant = 'success') {
  this.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant,
    },
  }, { position })
}

function toastError(text = '', position = 'top-right', title = '¡Error!', icon = 'XCircleIcon', variant = 'danger') {
  this.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant,
    },
  }, { position })
}

function toastAlert(text = '', position = 'top-right', title = '¡Alerta!', icon = 'AlertTriangleIcon', variant = 'warning') {
  this.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant,
    },
  }, { position })
}

export { toastSuccess, toastError, toastAlert }
