export default [
  {
    path: '/servicio-social/bolsa-de-trabajo/becarios',
    name: 'bolsa-becarios',
    component: () => import('@/views/servicio-social/bolsa-trabajo/becarios/BolsaBecariosList.vue'),
    meta: {
      pageTitle: 'Bolsa de Trabajo ',
      requiresAuth: true,
      action: 'social.becas.becario.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Becarios',
          active: true,
        },
      ],
    },
  },
]
