import axios from '@axios'

export default [
  {
    path: '/listar-formularios',
    name: 'listar-formularios-index',
    component: () => import('@/views/administrador/gestion-formulario/listarFormularios.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      action: 'recursos.formulario.index',
      resource: 'api',
    },
  },
  {
    path: '/recurso/formulario/:id_formulariox',
    name: 'formulario-x',
    component: () => import('@/views/administrador/gestion-formulario/formulario/Formulario.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      action: 'social.becas.convocatoria.postulante.store', // para que solo tengan acceso los estudiantes
      resource: 'api',
    },
    beforeEnter: (to, from, next) => {
      axios.get(`/recursos/formulario/${to.params.id_formulariox}`)
        .then(res => {
          if (res.data.autentificacion === 1) {
            if (res.data.codigo === 'SATISFACCION-COMEDOR' || res.data.codigo === 'SATISFACCION-OCADE' || res.data.codigo === 'SATISFACCION-SALUD') {
              next()
            } else {
              next({ name: 'no-autorizado' })
            }
          } else {
            next({ name: 'vista-anonima-x', params: { id_formulariox: to.params.id_formulariox } })
          }
        })
        .catch(() => {
          next({ name: 'dashboard-home' })
        })
    },
  },
  {
    path: '/recurso/formulario/anonimo/:id_formulariox',
    name: 'vista-anonima-x',
    component: () => import('@/views/administrador/gestion-formulario/vista-anonima/VistaAnonima.vue'),
    meta: {
      layout: 'full', // linea para ocultar el menu y cabecera de usuario
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      axios.get(`/recursos/formulario/${to.params.id_formulariox}`)
        .then(res => {
          if (res.data.autentificacion === 1) {
            next({ name: 'no-autorizado' })
          } else {
            next()
          }
        })
        .catch(() => {
          next({ name: 'dashboard-home' })
        })
    },
    props: true,

  },
  {
    path: '/recursos/formulario/:idFormulario/edit',
    name: 'nuevo-formulario-index',
    component: () => import('@/views/administrador/gestion-formulario/nuevo-formulario/indexFormulario.vue'),
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/recurso/edit-formulario/:idFormulario',
    name: 'formulario-satisfaccion-estudiante',
    component: () => import('@/views/administrador/gestion-formulario/satisfaccion-estudiante/EditFormularioView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      action: 'read',
      resource: 'Auth',
    },
    beforeEnter: (to, from, next) => {
      axios.get(`/recursos/formulario/${to.params.idFormulario}`)
        .then(res => {
          switch (res.data.codigo) {
            case 'SATISFACCION-COMEDOR':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Responable Comedor')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'SATISFACCION-SALUD':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Jefe Unidad Servicio Salud')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'SATISFACCION-OCADE':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Jef. OCADE')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'HOSTIGAMIENTO-ENCUESTA':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Comisión de prevención de hostigamiento')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break

            default:
              next({ name: 'no-autorizado' })
              break
          }
        }).catch(() => {
          next({ name: 'dashboard-home' })
        })
    },
  },
  {
    path: '/recurso/respuestas-formulario/satisfaccion/:idFormulario',
    name: 'respuestas-formulario-satisfaccion-estudiante',
    component: () => import('@/views/administrador/gestion-formulario/satisfaccion-estudiante/RespuestasFormularioSatisfaccion.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      action: 'read',
      resource: 'Auth',
    },
    beforeEnter: (to, from, next) => {
      axios.get(`/recursos/formulario/${to.params.idFormulario}`)
        .then(res => {
          switch (res.data.codigo) {
            case 'SATISFACCION-COMEDOR':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Responable Comedor')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'SATISFACCION-SALUD':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Jefe Unidad Servicio Salud')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'SATISFACCION-OCADE':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Jef. OCADE')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break
            case 'HOSTIGAMIENTO-ENCUESTA':

              if (JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Comisión de prevención de hostigamiento')) {
                next()
              } else {
                next({ name: 'no-autorizado' })
              }
              break

            default:
              next({ name: 'no-autorizado' })
              break
          }
        }).catch(() => {
          next({ name: 'dashboard-home' })
        })
    },
  },

]
