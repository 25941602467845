import axios from '@axios'

export default [
  {
    path: '/constancias-buena-salud',
    name: 'salud-constancia-list',
    component: () => import('@/views/salud/constancia/ConstanciaList.vue'),
    meta: {
      pageTitle: 'Gestión de constancias',
      breadcrumb: [
        {
          text: 'De buena salud',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'salud.constancias.index',
      resource: 'api',
    },
  },
  {
    path: '/servicio-salud/:id/constancia',
    name: 'download-pdf-constancia',
    beforeEnter: (to, from, next) => {
      const code = prompt('N° de Documento', 'N°00000-UNIA-DIBU-USES');
      axios({
        url: `/servicio-salud/${to.params.id}/constancia`,
        method: 'GET',
        params: { url_pdf: `${window.location.origin}${to.path}`, code },
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Constancia- COD-${to.params.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
      next(from)
    },
    meta: {
      requiresAuth: true,
      action: 'salud.constancias.url',
      resource: 'api',
    },
  },
]
