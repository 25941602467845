export default [
  {
    path: '/asistencia-social/opinion-postulante-beca/convocatorias',
    name: 'opinion-postulante-beca',
    component: () => import('@/views/servicio-social/asistencia-social/opinion-postulante-beca/opb-convocatorias/opb_ConvocatoriasList.vue'),
    meta: {
      pageTitle: 'Asistencia Social',
      requiresAuth: true,
      action: 'social.asistencia.opinion_postulante_beca.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'OPB',
        },
        {
          text: 'Convocatorias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asistencia-social/opinion-postulante-beca/convocatoria/:id/postulantes',
    name: 'opb-convocatoria-postulante-opinion',
    component: () => import('@/views/servicio-social/asistencia-social/opinion-postulante-beca/opb-postulantes/opb_PostulantesList.vue'),
    meta: {
      pageTitle: 'Asistencia Social',
      requiresAuth: true,
      action: 'social.asistencia.opinion_postulante_beca.store',
      resource: 'api',
      breadcrumb: [
        {
          text: 'OPB',
          active: true,
        },
        {
          text: 'Convocatorias',
          to: { name: 'opinion-postulante-beca' },
        },
        {
          text: 'Postulantes',
          active: true,
        },
      ],
    },
  },

]
