export default [
  {
    path: '/servicio-social/comedor/becarios',
    name: 'comedor-becarios',
    component: () => import('@/views/servicio-social/comedor-universitario/becarios/ComedorBecariosList.vue'),
    meta: {
      pageTitle: 'Comedor Universitario ',
      requiresAuth: true,
      action: 'social.becas.comedor.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Becarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-social/comedor/becarios/asistencias',
    name: 'comedor-becarios-asistencias',
    component: () => import('@/views/servicio-social/comedor-universitario/asistencias/ComedorAsistenciasList.vue'),
    // meta: {
    //   pageTitle: 'Comedor Universitario ',
    //   breadcrumb: [
    //     {
    //       text: 'Asistencias',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/servicio-social/comedor/nutricion',
    name: 'comedor-nutricion',
    component: () => import('@/views/servicio-social/comedor-universitario/nutricion/NutricionList.vue'),
    meta: {
      pageTitle: 'Comedor Universitario: Nutrición',
      requiresAuth: true,
      action: 'social.becas.nutricion.index',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Registros',
          active: true,
        },
      ],
    },
  },
  {
    path: '/servicio-social/comedor/reportes',
    name: 'comedor-reportes',
    component: () => import('@/views/servicio-social/comedor-universitario/reportes/VistaGeneral.vue'),
    meta: {
      pageTitle: 'Comedor Universitario',
      requiresAuth: true,
      action: 'social.becas.comedor.reporte_asistencia',
      resource: 'api',
      breadcrumb: [
        {
          text: 'Reportes',
          active: true,
        },
      ],
    },
  },

  // SATISFACCIÓN ESTUDIANTE
  {
    path: '/servicio-social/satisfaccion-estudiante',
    name: 'satisfaccion-estudiante-social',
    component: () => import('@/views/servicio-social/satisfaccion-estudiante/SatisfaccionSocial.vue'),
    meta: {
      requiresAuth: true,
      action: 'social.becas.comedor.index',
      resource: 'api',
      pageTitle: 'SATISFACCIÓN ESTUDIANTE',
      breadcrumb: [
        {
          text: 'Formularios',
          active: true,
        },
      ],
    },
  },
]
